import _addRoleToUserMutation from "./types/addRoleToUserMutation";
import _confirmPaymentMutation from "./types/confirmPaymentMutation";
import _createCategoryMutation from "./types/createCategoryMutation";
import _createProductMutation from "./types/createProductMutation";
import _createRoleMutation from "./types/createRoleMutation";
import _createUserMutation from "./types/createUserMutation";
import _currentUserQuery from "./types/currentUserQuery";
import _fetchAllCategoriesQuery from "./types/fetchAllCategoriesQuery";
import _fetchAllPendingPurchasesQuery from "./types/fetchAllPendingPurchasesQuery";
import _fetchAllPurchasesQuery from "./types/fetchAllPurchasesQuery";
import _fetchAllRolesQuery from "./types/fetchAllRolesQuery";
import _fetchCategoryByShrugQuery from "./types/fetchCategoryByShrugQuery";
import _fetchProductsByCategoryQuery from "./types/fetchProductsByCategoryQuery";
import _fetchPurchaseByIDQuery from "./types/fetchPurchaseByIDQuery";
import _fetchRecentlyPurchasesQuery from "./types/fetchRecentlyPurchasesQuery";
import _fetchAllProductsQuery from "./types/fetchAllProductsQuery";
import _fetchManyProductsByIDQuery from "./types/fetchManyProductsByIDQuery";

export const addRoleToUserMutation = _addRoleToUserMutation;
export const confirmPaymentMutation = _confirmPaymentMutation;
export const createCategoryMutation = _createCategoryMutation;
export const createProductMutation = _createProductMutation;
export const createRoleMutation = _createRoleMutation;
export const createUserMutation = _createUserMutation;
export const currentUserQuery = _currentUserQuery;
export const fetchAllCategoriesQuery = _fetchAllCategoriesQuery;
export const fetchAllPendingPurchasesQuery = _fetchAllPendingPurchasesQuery;
export const fetchAllPurchasesQuery = _fetchAllPurchasesQuery;
export const fetchAllRolesQuery = _fetchAllRolesQuery;
export const fetchCategoryByShrugQuery = _fetchCategoryByShrugQuery;
export const fetchProductsByCategoryQuery = _fetchProductsByCategoryQuery;
export const fetchPurchaseByIDQuery = _fetchPurchaseByIDQuery;
export const fetchRecentlyPurchasesQuery = _fetchRecentlyPurchasesQuery;
export const fetchAllProductsQuery = _fetchAllProductsQuery;
export const fetchManyProductsByIDQuery = _fetchManyProductsByIDQuery;